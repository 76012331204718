import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/tabakcentrumLogo.png'
import Services from '../../api/service';
import Bunduk from '../../images/logo-colorpng.png' 

const ClickHandler = () => {
    window.scrollTo(10, 0);
}
const SubmitHandler = (e) => {
    e.preventDefault()
}

const Footer = (props) => {
    return (
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="blog"/>
                                </div>
                                <p>Ontdek de beste selectie premium tabaksproducten bij Tabaak Centrum.</p>


                            </div>
                        </div>
                        <div className="col col-lg-1 col-md-0 col-sm-0 col-0">

                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget join-widget">
                                <div className="widget-title">
                                    <h3>Nieuwsbrief</h3>
                                </div>
                                <p>Meld je nu aan voor onze nieuwsbrief en ontdek de laatste aanbiedingen en nieuws over tabaak.</p>
                                <form onSubmit={SubmitHandler}>
                                    <input type="email" placeholder="support@gmail.com" required/>
                                    <button type="submit">Stuur nu <i className="ti-arrow-right"></i></button>
                                </form>
                            </div>
                        </div>

                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Producten</h3>
                                </div>
                                <ul>
                                    <li>Shisha</li>
                                    <li>Sigaren</li>
                                    <li>Tabak</li>
                                    <li>Pijpen</li>
                                    <li>Waterpijptabak</li>
                                    <li>Rookaccessoires</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright"> Copyright &copy; 2024 <a href="https://www.bunduk.com/" target="_blank" rel="noopener noreferrer"><img src={Bunduk} alt='Logo' width={100}/></a> |   All Rights  Reserved</p> 
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;