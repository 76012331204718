import React from "react";
import { Link } from 'react-router-dom'
import VideoModal from '../ModalVideo/VideoModal'

import simg1 from '../../images/smokingBack.jpg' 




const Hero = () => {
    return (
        <section className="wpo-hero-section-1">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-5 col-lg-5 col-12">
                        <div className="wpo-hero-section-text">
                            <div className="wpo-hero-title">
                                <h3 style={{color:'white'}}>Op zoek naar tabak?</h3>
                            </div>
                            <div className="wpo-hero-subtitle">
                            <p style={{color:'white'}}>Bent u op zoek naar de beste tabak? Bij Tabaak Centrum  vindt u een uitgebreide collectie van premium tabakssoorten uit de hele wereld. Of u nu houdt van klassieke smaken of op zoek bent naar iets nieuws, wij hebben de perfecte tabak voor elke liefhebber.</p>
                            </div>
                            <div className="btns">
                                <a href="#producten" className="btn theme-btn"> Onze Beste Aanbiedingen</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="right-vec">
                <div className="right-img">
                    <div className="r-img">
                        <img src={simg1} alt="hero"/> 
                    </div>
                </div>
            </div>
            <div className="pop-up-video">
                <div className="video-holder">

                </div>
            </div> */}
        </section>
    )
}



export default Hero;