import simg from '../images/service/1.jpg'
import simg2 from '../images/service/2.jpg'
import simg3 from '../images/service/3.jpg'
import simg4 from '../images/service/4.jpg'
import simg5 from '../images/service/5.jpg'
import simg6 from '../images/service/6.jpg'
import simg7 from '../images/service/1.jpg'
import simg8 from '../images/service/7.jpg'
import simg9 from '../images/service/8.jpg'
import simg10 from '../images/service/9.jpg'
import simg11 from '../images/service/5.jpg'
import simg12 from '../images/service/10.jpg'

import sIcon1 from '../images/partners/1.png'
import sIcon2 from '../images/partners/2.png'
import sIcon3 from '../images/partners/3.png'
import sIcon4 from '../images/partners/4.png'
import sIcon5 from '../images/partners/5.png'
import sIcon6 from '../images/partners/6.png'
import sIcon7 from '../images/icon/2.svg'
import sIcon8 from '../images/icon/3.svg'
import sIcon9 from '../images/icon/4.svg'
import sIcon10 from '../images/icon/5.svg'
import sIcon11 from '../images/icon/5.svg'
import sIcon12 from '../images/icon/5.svg'


import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'



const Services = [
    {
        Id: '1',
        sImg:simg,
        sIcon:sIcon1,
        sTitle: 'Tabak ',
        description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en autos.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '2',
        sImg:simg2,
        sIcon:sIcon2,
        sTitle: 'Pijpen',
        description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en auto.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImg:simg3,
        sIcon:sIcon3,
        sTitle: 'Shisha ',
        description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en auto.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        sImg:simg4,
        sIcon:sIcon4,
        sTitle: 'Sigaren',
        description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en auto.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '5',
        sImg:simg5,
        sIcon:sIcon5,
        sTitle: 'Vape',
        description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en auto.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '6',
        sImg:simg6,
        sIcon:sIcon6,
        sTitle: 'Rookaccessoires',
        description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en auto.',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    // {
    //     Id: '7',
    //     sImg:simg7,
    //     sIcon:sIcon7,
    //     sTitle: 'Waterpijptabak',
    //     description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en auto.',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '8',
    //     sImg:simg8,
    //     sIcon:sIcon8,
    //     sTitle: 'Rookaccessoires',
    //     description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en auto.',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '9',
    //     sImg:simg9,
    //     sIcon:sIcon9,
    //     sTitle: 'Tafel',
    //     description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en auto',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '10',
    //     sImg:simg10,
    //     sIcon:sIcon10,
    //     sTitle: 'Computer',
    //     description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en auto',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '11',
    //     sImg:simg11,
    //     sIcon:sIcon11,
    //     sTitle: 'Badkamer',
    //     description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en auto',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '12',
    //     sImg:simg12,
    //     sIcon:sIcon12,
    //     sTitle: 'Stoel',
    //     description:'We zijn een gecertificeerd bedrijf. We bieden de beste schoonmaakdiensten voor jou en je bedrijf aan, inclusief thuisservices en auto.',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
]
export default Services;