import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from '../../images/smokingback2.jpg'


const settings = {
    dots: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
};

const testimonial = [
    {
        Des: "Ik ben onder de indruk van het uitgebreide assortiment en de deskundige adviezen die ik heb ontvangen bij Tabak Centrum Shop. De producten zijn van hoge kwaliteit en de service was uitstekend. Ik zal zeker weer terugkomen.",
        Title: 'Jan de Jong',
        Sub: "Roker en Tabaksliefhebber",
    },
    {
        Des: "De ervaring bij Tabak Centrum Shop was top. Het personeel was uiterst vriendelijk en gaf me precies de informatie die ik nodig had om een weloverwogen keuze te maken. De levering was snel en alles was perfect verpakt.",
        Title: 'Lisa Verhoeven',
        Sub: "Zelfstandige Ondernemer",
    },
    {
        Des: "Tabak Centrum Shop biedt een fantastische selectie van tabaksproducten en accessoires. De klantenservice is geweldig en ze hebben me geholpen bij het vinden van precies wat ik zocht. Ik ben zeer tevreden en zal hen zeker aanbevelen aan anderen.",
        Title: 'Mark de Bruin',
        Sub: "Freelance Designer",
    }
]

const Testimonial = () => {
    return (
        <section className="wpo-testimonials-section section-padding" id="overons">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-5">
                        <div className="wpo-section-title">
                            <h2>Wat klanten zeggen? </h2>
                            <p> Ontdek hoe onze diensten gewaardeerd worden door te luisteren naar ervaringen van tevreden klanten.</p>
                        </div>
                    </div>
                </div>
                <div className="wpo-testimonials-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-12">
                                <img src={ts1} alt=""  style={{marginTop:'100px',width:'420px'}}/>
                        </div>
                        <div className="col-lg-7 col-12">
                            <div className="wpo-testimonials-text">
                                <div className="wpo-testimonials-slide owl-carousel">
                                    <Slider {...settings}>
                                        {testimonial.map((tesmnl, tsm) => (
                                            <div className="wpo-testimonials-slide-item" key={tsm}>
                                                <p>{tesmnl.Des}</p>
                                                <h5>{tesmnl.Title}</h5>
                                                <span>{tesmnl.Sub}</span>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;