import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Residential from './Residential';
import Commercial from './Commercial';


const ServiceSectionS2 = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    return (
        <section className="wpo-service-section section-padding" id='producten'>
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-5">
                        <div>
                        <h2  style={{fontSize:'35px',fontWeight:'700'}}>Ontdek Hoogwaardige Rookproducten bij Tabaak Centrum</h2>
                            <p  style={{fontSize:'20px'}}>Bij Tabaak Shop bieden wij een uitzonderlijk assortiment van hoogwaardige producten om uw rookervaring te verbeteren</p>
                        </div>
                    </div>
                    <div className="col-lg-7">
                    </div>
                </div>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <Residential/>
                    </TabPane>
                </TabContent>
            </div>
        </section>

    )
}

export default ServiceSectionS2;